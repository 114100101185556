/* IMPORT para angular calendar*/
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #f3f3f3; }


.link {
  color: #136df5;
  text-decoration: underline;
}

.link:hover {
  /*font-weight: 10;*/
  background-color: transparent !important;
  color: #1154b9;
}
/*--------------------------------- Botoes--------------------------------- */


.btn-concluir {
  width: 30px ;
  min-width: unset !important;
  padding: 0px !important;
  

  background-color: #1bc298;
  color: #FFFFFF;
  margin-right: 5px !important;
}
/*4b8fce
269e7a
*/

.btn-concluir:hover {
  background-color: rgb(21, 130, 135);
  color: #e4e3e3;
}


.btn-confirmar {
    background-color: #1b5ec2;
    color: #FFFFFF;
    font-weight: 400;
}
/*4b8fce
269e7a
*/

.btn-confirmar:hover {
    background-color: #14458d;
    color: #e4e3e3;
}

.btn-cancelar {
    background-color: #e44f4f;
    color: #FFFFFF;
}

.btn-cancelar:hover {
    background-color: #992323;
    color: #e4e3e3;
}

.btn-confirmar i, .btn-cancelar i {
    font-size: 14px;
    padding-bottom: 4px;
}

.btn-pequeno {
    width: 30px ;
    min-width: unset !important;
    padding: 0px !important;
    color: #FFFFFF;
    background-color: #415961;
    margin-right: 5px !important;
    text-align: center !important;

}

.btn-pequeno:hover {
    background-color: #202c30;
    color: #e4e3e3;

}

/*--------------------------------- Mensagens --------------------------------- */

.estilo-mensagem-sucesso {
    border-color: #ffffff;
    color: rgb(66, 71, 69);
    background-color: #e9f7da;
}

.estilo-mensagem-erro {
    border-color: #e8273b;
    color: #e8273b;
    background-color: #f8ced3;
}

.estilo-mensagem-advertencia {
    border-color: #f4a911;
    color: #f4a911;
    background-color: #f8e7c4
}

.estilo-mensagem-informacao {
    border-color: #2f80e7;
    color: rgb(22, 83, 163);
    background-color: #ffffff;
}

.estilo-dialog  {
  min-height: 50%;
  min-width: 70%;
  border-radius: 10px;
}

.estilo-dialog .mat-dialog-container { 
  resize: both;
}
/*--------------------------------- Datatable --------------------------------- */

.btn-excluir {
    width: 30px ;
    min-width: unset !important;
    padding: 0px !important;
    color: #FFFFFF;
    background-color: #ff5757;
    margin-right: 5px !important;
    text-align: center !important;
    
}

.btn-excluir:hover {
    background-color: #992323;
    color: #e4e3e3;
}



.btn-alterar {
    width: 30px ;
    min-width: unset !important;
    padding: 0px !important;
    color: #FFFFFF;
    background-color: #ff9b29;
    margin-right: 5px !important;
    text-align: center !important;

}

.btn-alterar:hover {
    background-color: #9e6e06;
    color: #e4e3e3;

}

.btn-consultar {
  width: 30px ;
  min-width: unset !important;
  padding: 0px !important;
  color: #FFFFFF;
  background-color: #ff9b29;
  margin-right: 5px !important;
  text-align: center !important;

}

.btn-consultar:hover {
  background-color: #9e6e06;
  color: #e4e3e3;

}

.espacamento {
  flex: 1 1 auto;
}

.btn-incluir {
  width: 30px ;
  min-width: unset !important;
  padding: 0px !important;
  color: #FFFFFF;
  background-color: #3f51b5;
  margin-right: 5px !important;

}

.btn-incluir:hover {
  background-color: #102181;
  color: #e4e3e3;

}

.btn-relatorios {
  width: 30px ;
  min-width: unset !important;
  padding: 0px !important;
  color: #FFFFFF;
  background-color: #9f29ff;
  margin-right: 5px !important;
  text-align: center !important;

}

.btn-relatorios:hover {
  background-color: #57049b;
  color: #e4e3e3;

}

.mat-row:hover {
    background-color: #fafafa;
    cursor: pointer;
 }

 .mat-row:active {
    background-color: #f3f0f0;
 }

  .paginacao-fixa {
    bottom: 0px;
    position: sticky;
    z-index: 10;
    color: #696969;
    background-color: #eff1f1;
    border-width: 5px;
  }

th.mat-header-cell {
    text-align: left;
    max-width: 300px;
    color: #415961;
    font-size: 0.95rem;
}

td.mat-cell {
    color: #818181;
}


/*--------------------------------- Scroll Bar --------------------------------- */
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f8f8f8; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(207, 207, 207); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(189, 189, 189); 
  }

    /* Handle on active */
    ::-webkit-scrollbar-thumb:active {
        background: rgb(153, 153, 153); 
      }

/*--------------------------- mat card ------------------------------ */
.mat-card-title {
    color:#415961;
    font-size: 1.8rem;
}

     
option { 
     font-size : 14px;                            /* Tamanho da Fonte */ 
     color     :#747373;                        /* Cor da Fonte */    
     padding   : 10px;  
}


.texto {
    font-size: 16px;
    color: #747373;
    padding: 20px;
    padding-top: 10px;
}

.link {
  cursor: pointer;
}

.link i {
font-size: 14pt;
color: rgb(61, 61, 61);

}

.link i:hover {
  color: rgb(27, 27, 27);
  }

.link:hover {
  background-color: rgb(240, 239, 239);
}
